<template>
<div class="tw-flex tw-w-full tw-flex-col lg:">
  <icons name="arrow-back" />
  <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
    <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
      <h6 class="header">Verification: Corporate Agents</h6>
      <div
          class="mr-2 tw-mt-5 lg:tw-mt-0 "
          style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)"
      >
        <v-text-field
            placeholder="Search for company"
            class="p-0"
            solo
            flat
            hide-details
            v-model="search"
        >
          <template #prepend>
            <v-icon :color="color" class="ml-5">
              mdi-magnify
            </v-icon>
          </template>
          <template #append>
            <v-icon :color="color">
              mdi-filter-variant
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-data-table
          :headers="headers"
          :mobile-breakpoint="0"
          :search="search"
          item-key="id"
          :single-select="false"
          height="available"
          :hide-default-footer="corporateAgencies.length <= 7"
          :items="corporateAgencies"
          :no-data-text="not_found"
          class="mt-4 mx-1 table"
          :loading="loading"


      >
        <template #header.companyLogo>
          <span class="status">Company Logo</span>
        </template>
        <template v-slot:[`item.companyLogo`]="{ item }">
          <img
              :src="item.companyLogo"
              alt="logo"
              v-if="item.companyLogo"
              class="company-logo"
          />
          <div
              class="no-image"
              v-else
              :style="{ backgroundColor: color }"

          >
            <h6 class="tw-flex tw-justify-center tw-items-center ">
              {{ item.companyName[0] }}
            </h6>
          </div>
        </template>
        <template #header.companyName>
          <span class="status">Company Name</span>
        </template>
        <template v-slot:[`item.companyName`]="{ item }">
          <span class="td-style">{{ item.companyName }}</span>
        </template>

        <template #header.companyEmail>
          <span class="status">Company Email</span>
        </template>
        <template v-slot:[`item.companyEmail`]="{ item }">
          <span class="td-style">{{ item.companyEmail }}</span>
        </template>
        <template #header.companyPhoneNumber>
          <span class="status">Company Phone Number</span>
        </template>
        <template v-slot:[`item.companyPhoneNumber`]="{ item }">
          <span class="td-style">{{ item.companyPhoneNumber }}</span>
        </template>
        <template #header.verification>
          <span class="status">Verification</span>
        </template>
        <template v-slot:[`item.verification`] ="{item}">
          <span class="td-style">
            <v-switch v-model="item.isCompanyVerified" @change="toggleVerify(item)"
                inset
                      color="#004AAD"
            ></v-switch>
          </span>
        </template>
        <template v-slot:[`item.actions`]>
          <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <td class="d-flex justify-center">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :color="color">mdi-dots-horizontal</v-icon>
                </v-btn>
              </td>
            </template>
            <v-list>
              <v-list-item class="" >
                <v-list-item-title
                    style="color: black"
                    class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                  <img
                      alt=""
                      class="tw-mr-2 icon-size"
                  />
                  View
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </div>
  <modal :dialog="showModal" :title="modalTitle"
         :description="modalDesc" icon="oops"
         @close="handleModal" @verifyTransportCompany="verifyCorporateAgent"
        :loading="modalLoading"/>
</div>
</template>

<script>
import Icons from "../components/reuseables/Icons";
import {getAllCorporateAgents, verifyCorporateAgentById} from "@/services/api/APIService";
import Modal from "../components/reuseables/Modal";
export default {
  name: "VerifyCorporateAgentView",
  components: {Modal, Icons},
  data(){
    return{
      color: "#004AAD",
      search : "",
      corporateAgencies:[],
      not_found : "Transport Companies will show here",
      headers: [
        { text: "", value: "companyLogo" },
        { text: "", value: "companyName" },
        { text: "", value: "companyEmail" },
        { text: "", value: "companyPhoneNumber" },
        { text: "", value: "verification" },
        { text: "", value: "actions", align: "end" },
      ],
      loading : false,
      showModal : false,
      modalLoading : false,
      modalTitle: "",
      modalDesc : " ",
      corporateAgentId:null
    }
  },

  methods:{
    handleModal(){
      if (this.showModal){
        this.getAllCorporateAgents()
      }
      this.showModal = !this.showModal

    },
   async verifyCorporateAgent(){
      this.modalLoading = true
      if (this.corporateAgentId) {
        await verifyCorporateAgentById(this.corporateAgentId).then(res => {
          this.$displaySnackbar({
            message: res.data.detail,
            success: true
          })
          this.modalLoading = false
          this.handleModal()

        }).catch(err => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false
          })
          this.modalLoading = false
        })
      }
    },
   async getAllCorporateAgents(){
      this.loading = true
      await getAllCorporateAgents().then(res =>{
        console.log(res.data)
        this.corporateAgencies = res.data
        this.loading = false
      }).catch(err => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false
        })
        this.loading = false
      })
    },
    toggleVerify(item){
     if (!item.isCompanyVerified){
       this.modalTitle = "Unverify"
       this.modalDesc ="Are you sure, you want to unverify "+ item.companyName+ "?"
     }
     else {
       this.modalTitle = "Verify"
       this.modalDesc ="Are you sure, you want to verify "+ item.companyName+ "?"
     }
     this.corporateAgentId = item.id
     this.handleModal()
    }
  },
 async created() {
    await this.getAllCorporateAgents()
  }
}
</script>

<style scoped lang="scss">
.company-dashboard{
  display: flex;
  background: #FFFFFF;
  width: 100%;
  min-height: 70vh;
  max-height: available;
  border-radius: 10px;
  flex-direction: column;
}
.header{
  font-family: 'Inter', sans-serif ;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #004AAD;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.table{
  width: 100%;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  text-transform: capitalize;

}
.company-logo{
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.no-image{
  display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
}
</style>